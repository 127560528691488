<template>
  <div>
    <section class="section">
      <div class="container">
        <div class="content">
          <h2>Whoops, got turned around</h2>
          <p>Something unexpected happened.</p>
          <p>Which can happen from time to time... no big deal.</p>
          <p>Please try going back to your last screen or</p>
          <button 
            @click="go()"
            class="button"
          >
           Goto Deals page
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  methods: {
    go() {
     let path = "/"
     this.$router.push(path);
    },
  }
}
</script>